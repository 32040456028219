<template>
  <div class="view-outer">
    <div class="view-inner">
      <h1 class="title">随机数生成器</h1>

      <div class="output-box">
        随机输出：
        <h3 class="output-value">{{ outputValue }}</h3>
      </div>

      <el-form class="form-params" label-width="120px">
        <el-form-item label="序号">
          <el-input-number
            v-model="plist.index"
            :min="1"
            :max="100000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="时间戳">
          <el-date-picker
            v-model="plist.timestamp1"
            type="datetime"
            value-format="timestamp"
            placeholder="选择日期时间"
            default-time="12:00:00"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="长度">
          <el-input-number
            v-model="plist.length"
            :min="1"
            :max="100"
            label="长度"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="盐">
          <el-input v-model="plist.salt"></el-input>
        </el-form-item>

        <el-form-item label="输出字符集">
          <el-input
            v-model="plist.charset"
            placeholder="请在此列出可以输出的所有字符"
          >
            <el-select
              class="el-select-charset"
              v-model="selectedCharset"
              slot="append"
              placeholder="请选择"
            >
              <el-option label="二进制数字" value="2"></el-option>
              <el-option label="八进制数字" value="8"></el-option>
              <el-option label="十进制数字" value="10"></el-option>
              <el-option label="十六进制数字" value="16"></el-option>
              <el-option label="Base64字符" value="64"></el-option>
              <el-option label="所有可显示字符" value="100"></el-option>
              <el-option label="自定义" value="0"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
      </el-form>

      <el-button class="btn-generate" type="primary" @click="handleClickGen">
        生成
      </el-button>
    </div>

    <div v-show="false">
      <pre> debug:  {{ debug.plainText }} </pre>
    </div>
  </div>
</template>

<script>
import sha256 from "crypto-js/sha256";

const HexCharMapping = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  a: 0x0a,
  b: 0x0b,
  c: 0x0c,
  d: 0x0d,
  e: 0x0e,
  f: 0x0f,
};

const CharsetTable = {
  0: "",
  2: "01",
  8: "01234567",
  10: "0123456789",
  16: "0123456789abcdef",
  64: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz+/",
  100: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz`-=[]\\;',./~!@#$%^&*()_+{}|:\"<>?",
};

export default {
  name: "Random",

  data() {
    return {
      outputValue: "2333",
      plist: {
        index: 1,
        length: 1,
        salt: "",
        charset: "",
        timestamp0: 0,
        timestamp1: 0,
        timestamp2: 0,
        prev: "",
      },
      selectedCharset: "16",
      debug: {
        plainText: "",
      },
    };
  },

  watch: {
    selectedCharset: function (value) {
      this.loadCharset(value);
    },
  },

  mounted() {
    this.init();
    this.handleClickGen();
    this.handleClickGen();
  },

  methods: {
    init() {
      let date = new Date();
      let timestamp = date.getTime();
      this.plist.index = 1;
      this.plist.length = 16;
      this.plist.timestamp0 = timestamp;
      this.plist.timestamp1 = timestamp;
      this.plist.timestamp2 = timestamp;
      this.plist.salt = this.hash("" + timestamp);
      this.loadCharset(this.selectedCharset);
    },

    loadCharset(key) {
      this.plist.charset = CharsetTable[key];
    },

    hash(text) {
      return sha256(text) + "";
    },

    handleClickGen() {
      let date = new Date();
      this.plist.timestamp2 = date.getTime();

      let plainText = this.preparePlainText(this.plist);
      let sum = this.hash(plainText);
      this.outputValue = this.generate(sum, this.plist);
      this.debug.plainText = plainText;

      // for next
      this.plist.index++;
      this.plist.timestamp1 = date.getTime();
      this.plist.prev = sum;
    },

    preparePlainText(plist) {
      return JSON.stringify(plist, null, 4);
    },

    appendCharToOffset(offset, ch) {
      let n = HexCharMapping[ch];
      if (n == null) {
        n = 0;
      }
      return (offset << 4) + (n & 0x0f);
    },

    generate(sum, plist) {
      sum = sum.toLowerCase();
      let wantLength = plist.length;
      let charset = plist.charset.trim();
      let offset = 0;
      let builder = "";
      if (wantLength < 1) {
        wantLength = 8;
      }
      if (charset.length < 1) {
        charset = "0123456789abcdef";
      }
      for (let i = 0; i < sum.length; ++i) {
        let ch1 = sum.charAt(i);
        offset = this.appendCharToOffset(offset, ch1);
        if ((i & 0x01) == 0) {
          continue;
        }
        let index = offset & 0xff;
        let ch2 = charset.charAt(index % charset.length);
        builder += ch2;
        if (builder.length >= wantLength) {
          break;
        }
      }
      return builder;
    },
  },
};
</script>


<style scoped>
.view-outer {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.view-inner {
  padding: 20px;
  margin: 20px;

  box-shadow: 0px 0px 15px #888888;
  border-radius: 5px;
}

.output-box {
  background-color: #333;
  color: #ddd;
  padding: 30px;
  border-radius: 5px;
}

.output-value {
  padding: 0px;
  margin: 0px;
  display: inline-block;
  font-size: 30px;
}

.btn-generate {
  width: 100%;
}

.title {
  text-align: right;
}

.form-params {
  margin-top: 20px;
}

.el-select-charset {
  width: 170px;
}
</style>